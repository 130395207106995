*,
::before,
::after {
	box-sizing: border-box;
	font-family: inherit;
	margin: 0px;
	padding: 0px;
}

#header-nav {
	align-self: flex-end;
	align-items: center;
	display: flex;
	grid-area: 2 / 2 / span 1 / span 3;
}

#header-nav .nav {
	padding: 0px;
	font-size: var(--tds-font-size-md);
	color: var(--tds-color-black);
}

#header-nav .nav-list {
	display: flex;
	position: relative;
}

#header-nav ul {
	list-style: none;
}

#header-nav .nav-list-item {
	border-top: 3px solid transparent;
	display: block;
	position: relative;
}

#header-nav .nav-list-item__active,
#header-nav .nav-list-item:active {
	border-top-color: var(
		--tds-header_theme-color-dark,
		var(--tds-header_theme-color-dark-default, var(--tds-color-brand))
	);
}

#header-nav .nav-list-item__button {
	align-items: center;
	background: none;
	cursor: pointer;
	display: flex;
}

#header-nav .nav-list-item__link,
#header-nav .nav-list-item__button {
	border-width: 3px 0px 0px;
	border-right-style: initial;
	border-bottom-style: initial;
	border-left-style: initial;
	border-right-color: initial;
	border-bottom-color: initial;
	border-left-color: initial;
	border-image: initial;
	border-top-style: solid;
	border-top-color: transparent;
	color: inherit;
	display: block;
	font-family: inherit;
	font-size: var(--tds-font-size-md);
	line-height: var(--tds-line-height-normal);
	padding: var(--tds-spacing-2) var(--tds-spacing-4);
	white-space: nowrap;
}

#header-nav a {
	text-decoration: none;
}

#header-nav .nav-list-item__active,
#header-nav .nav-list-item:active,
#header-nav .nav-list-item:hover,
#header-nav .nav-list-item__link:hover,
#header-nav .nav-list-item__link:active,
#header-nav .nav-list-item__button[aria-expanded='true'] {
	background: var(
		--tds-header_theme-color-light,
		var(--tds-header_theme-color-light-default, rgb(235, 243, 251))
	);
}

#header-nav .nav-list-item__arrow {
	--tds-icon_size: 14px;
	color: var(--tds-color-meteorite);
	margin-left: var(--tds-spacing-1);
}
