@charset "UTF-8";
/*! Lightning Design System 2.9.4 */
/* stylelint-disable declaration-no-important */
@font-face {
	font-family: 'TrailheadBold';
	src: local('TrailheadBold'), url('../common/assets/fonts/Trailhead-Bold.woff') format('woff');
}
@font-face {
	font-family: 'Salesforce Sans';
	src: local('Salesforce Sans'),
		url('../common/assets/fonts/SalesforceSans-Light.woff2') format('woff2');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Salesforce Sans';
	src: local('Salesforce Sans'),
		url('../common/assets/fonts/SalesforceSans-Regular.woff2') format('woff2');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Salesforce Sans';
	src: local('Salesforce Sans'),
		url('../common/assets/fonts/SalesforceSans-Italic.woff2') format('woff2');
	font-style: italic;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Salesforce Sans';
	src: local('Salesforce Sans'),
		url('../common/assets/fonts/SalesforceSans-Bold.woff2') format('woff2');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Salesforce Sans';
	src: local('Salesforce Sans'),
		url('../common/assets/fonts/SalesforceSans-BoldItalic.woff2') format('woff2');
	font-style: italic;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Neutraface';
	src: local('Neutraface'), url('../common/assets/fonts/Neutraface.woff') format('woff');
}
